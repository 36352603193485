<template>
  <div class="box_1">
    <div class="header cursor">
      <div class="text cursor" @click="goToMenuPage()">{{ pageTitle }}</div> 
    </div>

    <!-- <div class="w_200 h_200 x7 fs_50 ml_400 mt_400">
      那达慕
    </div> -->

    <div class="content_box mt_20 cursor" @click="goToMenuPage()">
      <div class="chart_box">
        <bing-tu :value="personData" />
      </div>
    </div>
  </div>
</template>

<script>
  import BingTu from '../charts/person-bing-tu.vue'
  import ZhuZhuangTu from '../charts/zhu-zhuang-tu.vue'

  export default {
    name: 'showPage1',
    components: { ZhuZhuangTu, BingTu, },
    data() {
      return {
        activeIndex: 0,

        pageTitle: '',

        personData: {},

      }
    },
    mounted() {
      this.pageTitle = this.$route.params.val

      if (this.pageTitle == '巴林右旗人口统计') {
        this.personData = {
          title: '人口统计',
          isShowTitle: false,
          data: [
            {"name": "14岁以下", "value": 44780, "unit": '人'},
            {"name": "15-64岁", "value": 118983, "unit": '人'},
            {"name": "65岁以上", "value": 3899, "unit": '人'},
          ]
        }
      }

      if (this.pageTitle == '巴林右旗大板镇人口统计') {
        this.personData = {
          title: '人口统计',
          isShowTitle: false,
          data: [
            {"name": "14岁以下", "value": 12817, "unit": '人'},
            {"name": "15-64岁", "value": 59600, "unit": '人'},
            {"name": "65岁以上", "value": 4580, "unit": '人'},
          ]
        }
      }

    },
    methods: {
      // 跳转到 menu 页面
      goToMenuPage() {
        this.$router.back()
        // this.$router.push({
        //   path: "/bigscreen/menuPage"
        // })
      },
    }
  }
</script>

<style scoped lang="less">
  .activeColor { color: #2366fc !important; }

  .box_1 {
    width: 100%;
    height: 100%;
    padding: 10px;

    .header {
      width: 100%;
      height: 40px;
      line-height: 40px;
      text-align: center;

      .text {
        width: 50%;
        height: 40px;
        font-size: 22px;
        font-weight: bold;
        color: #fff;
        cursor: pointer;
        // background-color: #2366fc;
        margin: 0 auto;
      }
    }

    .content_box {
      width: 100%;
      height: calc(100% - 50px);
      margin-top: 10px;
      position: relative;

      .chart_box {
        width: 100%;
        height: 100%;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
      }
    }
  }
</style>